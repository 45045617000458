import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "appshell",
    redirect: "/home",
    component: () => import("../views/appShell.vue"),
    children: [
      {
        path: "home",
        name: "home",
        component: () => import("../views/home.vue"),
        meta: {
          requiresAuth: true,
          desc: "Inicio",
        },
      },
      {
        path: "scenarios",
        name: "scenarios",
        component: () => import("../views/scenarios.vue"),
        meta: {
          requiresAuth: true,
          desc: "Escenarios",
        },
      },
      {
        path: "scenarios/add",
        name: "scenarios_add",
        component: () => import("../views/scenarios_add.vue"),
        meta: {
          requiresAuth: true,
          desc: "Escenarios",
        },
      },
      {
        path: "scenarios_res",
        name: "scenarios_res",
        component: () => import("../views/scenarios_res.vue"),
        meta: {
          requiresAuth: true,
          desc: "Resultado",
        },
      },
    ],
  },
  {
    path: "/",
    name: "dialogshell",
    component: () => import("../views/dialogShell.vue"),
    children: [
      {
        path: "login",
        name: "login",
        component: () => import("../views/login.vue"),
        meta: {
          desc: "Login",
        },
      },
      {
        path: "logout",
        name: "logout",
        component: () => import("../views/logout.vue"),
        meta: {
          desc: "Logout",
        },
      },
      {
        path: "noaccess",
        name: "noaccess",
        component: () => import("../views/noaccess.vue"),
        meta: {
          desc: "Noaccess",
        },
      },
      {
        path: "/:catchAll(.*)",
        name: "nofound",
        component: () => import("../views/nofound.vue"),
        meta: {
          desc: "Nofound",
        },
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
  if (to.name !== "login" && requiresAuth && !store.state.user)
    next({
      name: "login",
    });
  else next();
});

export default router;
