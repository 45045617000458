import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

firebase.initializeApp({
  apiKey: "AIzaSyBPyc4IzqZZ4tGwCIkbO8-QpUjtuqA6tl8",
  authDomain: "dtpm-4a153.firebaseapp.com",
  databaseURL: "https://dtpm-4a153.firebaseio.com",
  projectId: "dtpm-4a153",
  storageBucket: "dtpm-4a153.appspot.com",
  messagingSenderId: "1066859418340",
  appId: "1:1066859418340:web:dbf2b0767e747bef9e52f1",
  measurementId: "G-W1DSZYX56V",
});

firebase
  .firestore()
  .enablePersistence({ synchronizeTabs: true })
  .catch((err) => {
    console.log(err);
  });

export { firebase };
