import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import { vuexfireMutations, firestoreAction } from "vuexfire";
import { firebase } from "@/firebase";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {
      uid: null,
      name: null,
      admin: null,
    },
    scenarios: [],
  },
  plugins: [createPersistedState()],
  mutations: {
    setUser: (state, payload) => {
      state.user = payload;
    },
    ...vuexfireMutations,
  },
  actions: {
    bindScenarios: firestoreAction(({ state, bindFirestoreRef }) => {
      if (state.user.admin)
        return bindFirestoreRef(
          "scenarios",
          firebase
            .firestore()
            .collection("scenarios")
            .orderBy("creation_timestamp", "desc"),
          { reset: false, wait: true, maxRefDepth: 0 }
        );
      else
        return bindFirestoreRef(
          "scenarios",
          firebase
            .firestore()
            .collection("scenarios")
            .where("user_uid", "==", state.user.uid)
            .orderBy("creation_timestamp", "desc"),
          { reset: false, wait: true, maxRefDepth: 0 }
        );
    }),
  },
  modules: {},
});
